import React from "react";
// import Icon from "./Icon";
import utils from "../../functions/utils";
import FauxSplit from "./FauxSplit";
import './GenList.scss';



export default function GenList(props){
    const {className, id, type, name, style, children, } = props;

    // return
    const css = utils.classNames("lds-fauxlist", className, type && `is-type-${type}`, name && `is-name-${name}`);
    return (
        <div className={css} style={style} id={id}>
            <div className="lds-fauxlist-top">
                <FauxSplit type="header" />
            </div>
            <div className="lds-fauxlist-bottom">
                <FauxSplit type="rows">{children}</FauxSplit>
            </div>
        </div>
    );
}



// Avatar.Initails = Initails;

