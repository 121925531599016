import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../core";
// // import ChatModal from "./MessagesChatModal";
// import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
// import DemosButton from '../ui/DemosButton';
import TopNav from "../nav/TopNav";

// // import MessagesNewModal from "../../pages/messages/MessagesNewModal"; // MessagesNewModal
// // import FormErrorModal from "./FormErrorModal";
// // import Modal from "../../components/containers/Modal";
// // import FauxSplit from "../../components/demos/FauxSplit";
// // import GenList from "../../components/demos/GenList"; 
// import "../../pages/messages/Messages.scss";
// import "./FormError1.scss";
// // import "../ui/GenLayout.scss";

import "./GenForm.scss";
import GenList from "./GenList";




export default function GenForm(props){
    let {children, className, id, onClickCTA, panel, isError, } = props;

    // render
    const css = utils.classNames("lds-genform", className, isError && 'is-error');
    return (
        <div className={css} id={id}>

            <div className="lds-genform-section is-name-1">

                <Sprite.Button debug={0} style={{top: 0, height: 30, width: 350, }} />

                <Sprite.Button debug={0} style={{top: 50, height: 60, width: 400, cursor: 'text', }} />
                <Sprite.Button debug={0} style={{top: 50, height: 60, width: 400, cursor: 'text', }} />
            </div>

            <div className="lds-genform-section is-name-2">

                <Sprite.Button debug={0} style={{top: 120, height: 60, width: 400, cursor: 'text', }} />
                <Sprite.Button debug={0} style={{top: 200, height: 100, width: 770, cursor: 'text', }} />

                <Sprite.Button debug={0} style={{top: 380, height: 170, width: 320, }} />

                <Sprite.Button debug={0} style={{top: 630, height: 30, width: 250, }} />

                <Sprite.Button debug={0} style={{top: 690, height: 60, width: 770, cursor: 'text', }} />

            </div>
            <div className="lds-genform-section is-name-3">

                <Sprite.Button debug={0} style={{top: 770, height: 110, width: 770, cursor: 'text', }} />

            </div>


        </div>
    );
}



