import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import DemosButton from '../ui/DemosButton';
// import TopNav from "../../components/nav/TopNav";
// import MessagesNewModal from "../../pages/messages/MessagesNewModal"; // MessagesNewModal
// import FormErrorModal from "./FormErrorModal";
import Modal from "../../components/containers/Modal";
import "../../pages/messages/Messages.scss";
import "./PatientPanel1.scss";
// import "../ui/GenericLayout.scss";
import GenericLayout from "../../components/generic/GenLayout";
import GenList from "../../components/generic/GenList";



// --- panel --- //

function Panel(props){
    const {isJohn, onClickLinked, onClickCloser, } = props;



    // render
    const css = utils.classNames('lds-patientpanel1-panel', isJohn && 'is-john');
    return (
        <div className={css}>
            <div className="lds-patientpanel1-panel-inner">

                <Sprite.Button onClick={(onClickCloser)} debug={1} style={{top: 20, left: 310, width: 40, height: 30, }} />

                {!isJohn && <Sprite.Button onClick={(onClickLinked)} debug={1} style={{top: 984, left: 290, width: 70, height: 30, }} />}
                {isJohn && <Sprite.Button onClick={(onClickLinked)} debug={1} style={{top: 914, left: 290, width: 70, height: 30, }} />}

            </div>
        </div>
    )
}



// --- list --- //

function List(props){
    const {selected, onClickRow, panelOpen, } = props;


    // render
    const css = utils.classNames("lds-patientpanel1-row", selected && `is-selected`, panelOpen && `is-`);
    return (
        <GenList>
            <div className={css} onClick={onClickRow}></div>
            {/* <Sprite.Button className="lds-patientpanel1-row" onClick={(onClickCTA)} debug={1} style={{top: 24, left: 20, width: 2000, }}></Sprite.Button> */}
            {/* <div className="lds-patientpanel1-list-row">asdfsadf</div> */}
        </GenList>
    );
}



// --- page --- //

export default function Page(){
    const mode = null;
    // const dispatch = useDispatch();
    const [selected, setSelected] = React.useState(false);
    const [panelOpen, setPanelOpen] = React.useState(false);
    const [isJohn, setJohn] = React.useState(false);

    // const onClickCTA = () => dispatch({call: "SHOW_MODAL", component: <FormErrorModal />});
    const onClickJohn = () => {
        setJohn(true);
    }

    const onClickLinked = () => {
        const isj = !isJohn;
        setJohn(isj);
        if(isj && selected) setSelected(false);


        const elements = document.getElementsByClassName("lds-patientpanel1-panel");
        if (elements.length > 0) elements[0].scroll({top: 0, left: 0, }); // behavior: 'smooth'
        
    }

    const onClickRow = () => {
        // const sel = !selected;
        // setSelected(sel);
        // setPanelOpen(sel);
        // if(sel && isJohn) setJohn(false);
        setSelected(true);
        setPanelOpen(true);
        setJohn(false);
    }

    const onClickCloser = () => {
        setSelected(false);
        setPanelOpen(false);
        setJohn(false);
    }

    // components
    const panelComp = (panelOpen && <Panel isJohn={isJohn} onClickLinked={onClickLinked} onClickCloser={onClickCloser} />);
    const listComp = <List selected={selected} onClickRow={onClickRow} panelOpen={panelOpen} />;

    // render
    return (
        <GenericLayout sidebar={false} panel={panelComp} list={listComp} />
    );
}




