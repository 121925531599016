import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import DemosButton from '../ui/DemosButton';
// import Loader from "../../components/core/Loader";
// import Layout from "../../components/containers/Layout";
// import Layout from "./Layout1";
// import PatientsPanel from "./Panel1";
import TopNav from "../../components/nav/TopNav";
import "../../pages/messages/Messages.scss";
import "./DetailsPanel2.scss";



// MARGIN MODE TEST
const marginMode = 0; // 0 = off, 1 = current, 2 = proposed

// DEBUG
const debug = 0;


export default function Page(){
    const dispatch = useDispatch();
    const [mode, setMode] = React.useState(false);
    
    React.useEffect(() => {
        // setTimeout(() => setPanel(true), 1000)

    }, []);

    // const onClickPanelClose = () => {
    //     setMode(false);
    // };

    // const onClickPanelMin = () => {
    //     setMode('min');
    // };

    // const onClickPanelMax = () => {
    //     setMode('max');
    // }

    // const onClickRow = () => {
    //     setMode(mode === 'max' ? false : 'max');
    // };

    // const onClickPanel = () => {
    //     alert('testing');
    // }

    const toggleMinMax = () => {
        setMode((mode === 'max') ? 'min' : 'max');
    }

    const toggleOpenClose = () => {
        setMode((mode === false) ? 'max' : false);
    }

    const onClickRow = () => {
        if(mode === 'min') setMode('max');
        else if(mode === 'max') setMode(false);
        else setMode('max');
    }


    // render
    const css = utils.classNames("lds-detailspanel2", mode && `is-mode-${mode}`);
    return (
        <React.Fragment>
            
            <DemosButton />

            <TopNav />
           

            <div className={css}>
                <div className="lds-detailspanel2-top">

                </div>
                <div className="lds-detailspanel2-content">

                    {/* <div className="lds-detailspanel2-left"></div> */}
                    <div className="lds-detailspanel2-middle" onClick={() => onClickRow()}>



                        <Sprite.Split id="lds-detailspanel2-list">
                            <Sprite.Left />
                            <Sprite.Right />
                        </Sprite.Split>


                    </div>
                    <div className="lds-detailspanel2-right">
                        <div className="lds-detailspanel2-right-inner">
                            
                            <div className="lds-detailspanel2-panel">
                                <div className="lds-detailspanel2-panel-top">
                                    

                                    <div className="lds-detailspanel2-button is-name-min" onClick={() => toggleMinMax()}></div>
                                    <div className="lds-detailspanel2-button is-name-closer" onClick={() => setMode(false)}></div>
                                    <div className="lds-detailspanel2-button is-name-max2" onClick={() => toggleMinMax()}></div>
                                    <div className="lds-detailspanel2-button is-name-closer2" onClick={() => setMode(false)}></div>
                                    

                                </div>
                                <div className="lds-detailspanel2-panel-bottom">
                                    <div className="lds-detailspanel2-panel-content">

                                        <div className="lds-detailspanel2-panel-blocker" onClick={() => toggleMinMax(false)}></div> 

                                    </div>
                                </div>
                            </div>
                            {/* 
                            <div className="lds-detailspanel2-panel">

                                
                                <div className="lds-detailspanel2-button is-name-min" onClick={() => toggleMinMax()}></div>
                                <div className="lds-detailspanel2-button is-name-closer" onClick={() => setMode(false)}></div>
                                <div className="lds-detailspanel2-button is-name-max2" onClick={() => toggleMinMax()}></div>
                                <div className="lds-detailspanel2-button is-name-closer2" onClick={() => setMode(false)}></div>
                                
                                <div className="lds-detailspanel2-panel-blocker" onClick={() => toggleMinMax(false)}></div> 
                                
                            </div>
                            */}

                        </div>
                    </div>

                </div>


            </div>

        </React.Fragment>

    )

    // END PAGE
}