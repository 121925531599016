import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import DemosButton from '../ui/DemosButton';
// import Loader from "../../components/core/Loader";
// import Layout from "../../components/containers/Layout";
// import Layout from "./Layout1";
// import PatientsPanel from "./Panel1";
import TopNav from "../../components/nav/TopNav";
import GenList from "../../components/generic/GenList";
import "../../pages/messages/Messages.scss";
import "./ResLayout1.scss";



// MARGIN MODE TEST
const marginMode = 0; // 0 = off, 1 = current, 2 = proposed

// DEBUG
const debug = 0;


export default function Page(){
    const dispatch = useDispatch();
    const [mode, setMode] = React.useState(2);
    const [option, setOption] = React.useState(0);
    
    React.useEffect(() => {
        // setTimeout(() => setPanel(true), 1000)
    }, []);


    const toggleMinMax = () => {
        setMode((mode === 'max') ? 'min' : 'max');
    }

    const toggleOpenClose = () => {
        setMode((mode === false) ? 'max' : false);
    }

    const onClickRow = () => {
        if(mode === 'min') setMode('max');
        else if(mode === 'max') setMode(false);
        else setMode('max');
    }

    const onClickOption = (n) => {
        setOption(n);
    }

    const div = (
        <ol>
            <li>This is one</li>
            <li>This is two</li>
            <li>This is three</li>
        </ol>
    );

    // options
    const options = [
        {
            tooltip: "Responsive panel", 
            description: (
                <React.Fragment>
                    <p>Responsive panel sits on top of the main content:</p>
                    <ol>
                        <li>Click anywhere on the list to open the Details Panel.</li>
                        <li>Shrink the browser so the panel sits on top of the list.</li>
                    </ol>
                </React.Fragment>
            ), 
        },
        {
            tooltip: "Responsive panel with horizontal scrolling", 
            description: (
                <React.Fragment>
                    <p>Scroll horizontally to reveal hidden content:</p>
                    <ol>
                        <li>Click anywhere on the list to open the Details Panel.</li>
                        <li>Shrink the browser so the panel sits on top of the list.</li>
                        <li>Place cursor over the list and scroll horizontally.</li>
                    </ol>
                </React.Fragment>
            ), 
        },
    ];

    // render
    const css = utils.classNames("lds-reslayout1", mode && `is-mode-${mode}`, `is-option-${option}`);
    return (
        <React.Fragment>
            
            <DemosButton options={options} selection={option} onClick={onClickOption} />

            <TopNav />
           

            <div className={css}>
                <div className="lds-reslayout1-top">

                </div>
                <div className="lds-reslayout1-content">

                    <div className="lds-reslayout1-left is-abs-all" style={{position: 'relative', }}>

                        <Sprite.Button  debug={0} style={{top: 20, left: 20, width: 230, height: 50, }} />

                    </div>
                    <div className="lds-reslayout1-middle" onClick={() => onClickRow()}>


                        <GenList style={{padding: "0 24px", }} />

                        {/* 
                        <GenList>
                        </GenList>

                        <Sprite.Split id="lds-reslayout1-list">
                            <Sprite.Left />
                            <Sprite.Right />
                        </Sprite.Split>

                        */}


                    </div>
                    <div className="lds-reslayout1-right">
                        <div className="lds-reslayout1-right-inner">
                            
                            <div className="lds-reslayout1-panel">
                                <div className="lds-reslayout1-panel-top">
                                    

                                    <div className="lds-reslayout1-button is-name-min" onClick={() => toggleMinMax()}></div>
                                    <div className="lds-reslayout1-button is-name-closer" onClick={() => setMode(false)}></div>
                                    <div className="lds-reslayout1-button is-name-max2" onClick={() => toggleMinMax()}></div>
                                    <div className="lds-reslayout1-button is-name-closer2" onClick={() => setMode(false)}></div>
                                    

                                </div>
                                <div className="lds-reslayout1-panel-bottom">
                                    <div className="lds-reslayout1-panel-content">

                                        <div className="lds-reslayout1-panel-blocker" onClick={() => toggleMinMax(false)}></div> 

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </React.Fragment>

    )

    // END PAGE
}