


import React from "react";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
// import {Sprite, Select, Alert, Icon2, } from "../../components/core";
// import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
// import Modal from "../../components/containers/Modal";
// import Layout from "../../components/containers/Layout";

// import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from "@mui/material";
import "./Demos.scss";


const debug = 0;

// const data = {
//     "General": [
//         {}
//     ],
// };

// const data = [
//     {title: "General", },
// ]


// --- main --- //

export default function DemosButton(props){
    let {style, onClick, optionCount, selection, options, position, } = props;

    if(!Array.isArray(options)) options = [];

    const [open, setOpen] = React.useState(false);
    const toggleOpen = () => {
        setOpen(!open);
    };


    React.useEffect(() => {
        // --- WIP
    }, [selection]);

    // if(!optionCount) optionCount = 0;
    optionCount = options ? options.length : 0;

    let numbers = [];
    for(var i=0; i<optionCount; i++) numbers.push(i);

    const arr = numbers.map((n) => {
        const onClickLink = (e) => {
            e.preventDefault();
            if(onClick) onClick(n);
        }
        const onClickIcon = () => {
            setOpen(true);
        }

        let obj = options[n];

        // return
        const css = (selection === n) ? 'is-selected' : '';
        return (
            <Tooltip title={obj.tooltip} key={n} placement="top" arrow>
                <a href="/demos" key={n} className={css} onClick={onClickLink}>{`Demo ${n+1}`}<InfoOutlined onClick={onClickIcon} /></a>
            </Tooltip>
        );

        // return (
        //     <Tooltip title="San Antonio Phar - 3456 South Lamar Boulevard" arrow>
                
        //     </Tooltip>
        // );
        // return <a href="/demos" key={n} className={css} onClick={onClick3}>{`Demo ${n+1}`}</a>;
    });

    const desc = options[selection]?.description;


    // render
    const css = utils.classNames(position && `is-position-${position}`);
    return (
        <React.Fragment>
            <a href="/demos" id="lds-demos-topbutton"></a>

            <div style={style} id="lds-demos-button" className={css}>
                <div id="lds-demos-button-inner">
                    <a href="/demos">&#10094; &nbsp;&nbsp;More demos</a>
                    {arr.length ? <span>|</span> : ''}
                    {arr}
                </div>
            </div>

            <Dialog fullWidth={true} open={open}>
                <DialogTitle id="alert-dialog-title">{"Demo " + (selection+1)}</DialogTitle>
                <DialogContent>{options[selection]?.description}</DialogContent>
                <Button onClick={toggleOpen}>Close</Button>
            </Dialog>
        </React.Fragment>
        
    );

}