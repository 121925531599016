import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import DemosButton from '../ui/DemosButton';
// import TopNav from "../../components/nav/TopNav";
// import MessagesNewModal from "../../pages/messages/MessagesNewModal"; // MessagesNewModal
// import FormErrorModal from "./FormErrorModal";
import Modal from "../../components/containers/Modal";
import "../../pages/messages/Messages.scss";
import "./FormError1.scss";
// import "../ui/GenLayout.scss";
import GenLayout from "../../components/generic/GenLayout";
import GenForm from "../../components/generic/GenForm";



function Modal2(props){
    const dispatch = useDispatch();
    const [isError, setError] = React.useState(false);

    const onOK = () => {
        setError(true);
        
        const elements = document.getElementsByClassName("MuiDialogContent-root");
        if (elements.length > 0) elements[0].scroll({top: 0, left: 0, behavior: 'smooth'});
    }

    return (
        <Modal noLoader size="lg" label="Edit Form" buttons={{ok: onOK, }}>
            <GenForm isError={isError} />
        </Modal>
    );
}


// --- modal --- //

function FormErrorModal(props){
    const dispatch = useDispatch();
    const [isError, setError] = React.useState(false);


    const onOK = () => {
        setError(true);
        
        const elements = document.getElementsByClassName("MuiDialogContent-root");
        if (elements.length > 0) elements[0].scroll({top: 0, left: 0, behavior: 'smooth'});
    }

    // render
    const css = utils.classNames(isError && 'is-error');
    return (
        <Modal noLoader size="lg" label="Edit Form" buttons={{ok: onOK, }}>


            {/**/}
            <Sprite.Container id="lds-msgdrill1-modal" className={css}>

                <div id="lds-msgdrill1-modal-group1" className="is-abs-all">

                    <Sprite.Button debug={0} style={{width: 400, top: 70, left: 0, height: 60,  }} />
                    <Sprite.Button debug={0} style={{width: 400, top: 150, left: 0, height: 60, cursor: 'text', }} />

                </div>
                    
                <div id="lds-msgdrill1-modal-group2" className="is-abs-all">
                    <Sprite.Button debug={0} style={{width: 330, top: 220, left: 0, height: 40,  }} />
                    <Sprite.Button debug={0} style={{width: 770, top: 260, left: 0, height: 110, cursor: 'text', }} />
                </div>

            </Sprite.Container> 


        </Modal>
    );
}







// --- page --- //

export default function Page(){
    const mode = null;
    const dispatch = useDispatch();


    const onClickCTA = () => dispatch({call: "SHOW_MODAL", component: <Modal2 />});

    // render
    const css = utils.classNames("lds-msgdrill1", mode && `is-mode-${mode}`);
    return (
        <React.Fragment>

            <DemosButton  />
            <GenLayout onClickCTA={onClickCTA} />

        </React.Fragment>
    );

    // end component
}






