





import React from "react";
// import Icon from "./Icon";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
import utils from "../../functions/utils";
import GenPhone from "../../components/generic/GenPhone";
import GenLayout from "../../components/generic/GenLayout";
import './PhoneTest1.scss';



export default function Page(){
    const [openPhone, setOpenPhone] = React.useState(false);
    const [openPhoneModal, setOpenPhoneModal] = React.useState(false);
    const [openPhoneSheet, setOpenPhoneSheet] = React.useState(false);

    // click handler
    const togglePhone = () => {
        setOpenPhone(!openPhone);
    }

    const togglePhoneModal = () => {
        setOpenPhoneModal(!openPhoneModal);
    }

    const togglePhoneSheet = () => {
        setOpenPhoneSheet(!openPhoneSheet);
    }

    // modal
    const phoneModal = {
        title: "Notifications",
        content: (
            <div id="phonetest1-notifications"></div>
        ),
        bottomHeight: 98,
        bottomContent: (
            <div id="phonetest1-notifications-bottom">

                {/* next button */}
                <Sprite.Button debug={0} style={{top: 20, left: 20, width: 340, }} onClick={togglePhoneModal} />
            </div>
        ),
    };



    // sheet
    const phoneSheet = {
        title: "Bottom Sheet",
        height: 153,
        content: (
            <div id="phonetest1-bottomsheet">
                <Sprite.Button debug={0} style={{top: 70, left: 20, width: 340, }} onClick={togglePhoneSheet} />
            </div>
            
        ),
    };

    
    // return
    return (
        <React.Fragment>


            <GenLayout onClickCTA={togglePhone} />


            <GenPhone 
                type="home" 
                open={openPhone} 
                modal={phoneModal} 
                sheet={phoneSheet} 
                openModal={openPhoneModal} 
                openSheet={openPhoneSheet} 
                onClickOutside={togglePhone} 
                onClickModalX={togglePhoneModal}
                onClickSheetX={togglePhoneSheet}
            >
                <div id="phonetest1-home">

                    {/* open modal button */}
                    <Sprite.Button debug={1} style={{top: 20, left: 20, }} onClick={togglePhoneModal} />


                    {/* open sheet button */}
                    <Sprite.Button debug={1} style={{top: 80, left: 20, }} onClick={togglePhoneSheet} />

                </div>
            </GenPhone>

    
        </React.Fragment>
    );
}





// Avatar.Initails = Initails;

