import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import DemosButton from '../ui/DemosButton';
import Loader from "../../components/core/Loader";
// import Layout from "../../components/containers/Layout";
// import Layout from "./Layout1";
// import PatientsPanel from "./Panel1";
import TopNav from "../../components/nav/TopNav";
import "../../pages/messages/Messages.scss";
import "./MsgDrill1.scss";






// --- demos --- //

const demos = [
    {
        tooltip: "Responsive panel", 
        description: (
            <React.Fragment>
                <p>Responsive panel sits on top of the main content:</p>
                <ol>
                    <li>Click anywhere on the list to open the Details Panel.</li>
                    <li>Shrink the browser so the panel sits on top of the list.</li>
                </ol>
            </React.Fragment>
        ), 
    },
    {
        tooltip: "Responsive panel with horizontal scrolling", 
        description: (
            <React.Fragment>
                <p>Scroll horizontally to reveal hidden content:</p>
                <ol>
                    <li>Click anywhere on the list to open the Details Panel.</li>
                    <li>Shrink the browser so the panel sits on top of the list.</li>
                    <li>Place cursor over the list and scroll horizontally.</li>
                </ol>
            </React.Fragment>
        ), 
    },
];




// --- page --- //


export default function Page(){
    const dispatch = useDispatch();
    // const [mode, setMode] = React.useState(false);
    const [demo, setDemo] = React.useState(0);
    
    const onClickDemo = (n) => {
        document.getElementById("lds-msgdrill1-middle-bottom").scrollTo(0, 0);
        setDemo(n);
    }
    
    React.useEffect(() => {
        // setTimeout(() => setPanel(true), 1000)

    }, []);



    // render
    const css = utils.classNames("lds-msgdrill1", `is-demo-${demo}`);
    return (
        <React.Fragment>

            <DemosButton options={demos} selection={demo} onClick={onClickDemo} position="left" />

            <TopNav />

            <div className={css}>


                <div className="lds-msgdrill1-left is-abs-all">


                    <Sprite.Button debug={0} style={{width: 230, top: 10, left: 20, height: 430, }} />

                </div>
                <div className="lds-msgdrill1-patientlist is-abs-all">


                    <Sprite.Button debug={0} style={{width: 250, top: 150, left: 20, height: 460, }} />

                </div>
                <div className="lds-msgdrill1-middle" style={{border: "red 1px solid;", }}>


                    <div className="lds-msgdrill1-middle-top is-abs-all">
                    </div>

                    <div className="lds-msgdrill1-middle-bottom is-abs-all" id="lds-msgdrill1-middle-bottom">


                    {demo === 0 && 
                        <div className="lds-msgdrill1-content is-abs-all">
                            
                            <Loader type="preload" duration={1000} debug={1} />
                            <Sprite.Button debug={1} style={{width: 760, top: 650, left: 0, height: 60, }} />

                        </div>
                    }


                    {demo === 1 && 
                        <div className="lds-msgdrill1-content is-abs-all">
                            
                            <Loader type="preload" duration={1000} debug={1} />
                            <Sprite.Button debug={1} style={{width: '100%', top: 602, left: 0, height: 110, }} />

                        </div>
                    }


                    </div>
                    



                </div>
                <div className="lds-msgdrill1-right">


                    <div className="lds-msgdrill1-panel">

                    </div>

                </div>


            
            
            </div>

        </React.Fragment>

    );
    // end component
}

/**/





// ========================= NOTES ========================= //

// use_event('page_form_submission');
// use_interface('top_nav', 'top_nav_interface');


// use_event('add_post_type', 'add_post_type_event');
// use_event('init', 'init_event');

// call_event('');
// call_interface();

// function init_event(){
    
// }

// function add_post_type_event(){

// }

// function top_nav_interface(){

// }
/*
export default class App extends React.Component {
    render() {
      // the matched child route components become props in the parent
      return (
        <div>
          Testing
        </div>
      )
    }
  }
*/

/*
export default function MsgDrill1(){

    return (
        <div>Drill down</div>
    );
}
*/
