import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Button} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Loader from "../../components/core/Loader";
import bcrypt from "bcryptjs";
import "./Auth.scss";

const debug = 0;

function validate(value, type, setter){
    if(typeof value !== "string" || !value.length){
        setter("A value is required");
        return true;
    }
    setter(null);
}

// Sprite.Container id="lds-auth-login"

export default function Screen(props){
    const {logout} = props;
    const dispatch = useDispatch();
    const {redirect, } = useGlobals();
    const nav = useNavigate();

    // logout
    React.useEffect(() => {
        if(logout){
            dispatch({call: "SET_AUTH", value: false, });
        }
    }, []);

    // settings
    const [name, setName] = React.useState("demo@lumistry.com");
    const [pass, setPass] = React.useState(null);
    const [nameError, setNameError] = React.useState(null);
    const [passError, setPassError] = React.useState(null);
    const [entering, setEntering] = React.useState(false);

    const onEmailChange = (e) => setName(e.target.value);
    const onPassChange = (e) => setPass(e.target.value);
    const onKeyPress = (e) => ((e.key == 'Enter') && onSubmit());
    // const onEnter = () => nav("/"); // go to URL

    // submitter
    const onSubmit = () => {

        // validate
        let hasError = false;
        if(validate(name, "name", setNameError)) hasError = true;
        if(validate(pass, "pass", setPassError)) hasError = true;
        if(hasError) return; // error   

        // encrypt values
        // NOTE: https://bcrypt-generator.com/

        var goodname = bcrypt.compareSync(name, '$2a$12$0b/6Vw1Gc6DzBKl0cQZ41.mrGI3HWCxG/UvKEuLXO2.3oaqwUFz2q'); // demo@lumistry.com
        const passwords = [
            // '$2a$12$j4ICI91asd7ZiKE8LVF5yOkCz0B8JV0U8j1I8PTmdutW3Dyb.ix9a', // old
            // '$2a$12$qoJIl3nMhZqk2tTf5zHTLuwlSyeqmmqqr9U7uN.IREMsaL6rySm/u', // lumistry23
            '$2a$12$.AGghFSoMgJB8cil6nvaRO95uy2ZUN1H6SxzvfDgN034FbBflZoje' // lumistry24
        ];
        let goodpass = false;
        passwords.forEach(pw => {
            if(bcrypt.compareSync(pass, pw)) goodpass = true;
        });
        if(!goodname || !goodpass) return setPassError('The email address or password is invalid.'); // error

        // passed!
        setEntering(true);
    }

    const onLoaderComplete = () => {
        const goto = typeof redirect === 'string' ? redirect : "/";
        dispatch({call: "SET_AUTH", value: true, onComplete: () => nav(goto), });
        dispatch({call: "SET_REDIRECT", value: false, });
    }


    return (
        <div className="is-centerstage">

            <div id="lds-login-form">
                {entering && <Loader color="translucent" open duration={2000} onComplete={onLoaderComplete} />}

                <div id="lds-login-form-title">Login</div>

                <Input debug={debug} label="Email address" onChange={onEmailChange} onKeyPress={onKeyPress} value={name} error={nameError} />
                <Input.Password debug={debug} label="Password" onChange={onPassChange} onKeyPress={onKeyPress} value={pass} error={passError}  rightIcon="eye" />

                <Button.Primary label="Continue" onClick={onSubmit} />

                <div><a href="/login/forgot" id="lds-login-form-forgot">Forgot password?</a></div>
            </div>

        </div>
    );
}
