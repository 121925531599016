import React from "react";
// import Icon from "./Icon";
import utils from "../../functions/utils";
import './FauxSplit.scss';



export default function FauxSplit(props){
    const {className, id, type, name, style, children, } = props;

    // return
    const css = utils.classNames("lds-fauxsplit", className, type && `is-type-${type}`, name && `is-name-${name}`);
    return (
        <div className={css} style={style} id={id}>
            <div className="lds-fauxsplit-left">{children}</div>
            <div className="lds-fauxsplit-right"></div>
        </div>
    );
}


// Avatar.Initails = Initails;

