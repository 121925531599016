import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../core";
// // import ChatModal from "./MessagesChatModal";
// import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
// import DemosButton from '../ui/DemosButton';
import TopNav from "../nav/TopNav";
// // import MessagesNewModal from "../../pages/messages/MessagesNewModal"; // MessagesNewModal
// // import FormErrorModal from "./FormErrorModal";
// // import Modal from "../../components/containers/Modal";
// // import FauxSplit from "../../components/demos/FauxSplit";
// // import GenList from "../../components/demos/GenList"; 
// import "../../pages/messages/Messages.scss";
// import "./FormError1.scss";
// // import "../ui/GenLayout.scss";

import "./GenLayout.scss";
import GenList from "./GenList";





GenLayout.Top = (props) => {
    const {children, className, } = props;
    const css = utils.classNames("lds-genlayout-top", className);
    return (<div className={css}>{children}</div>);
}

GenLayout.Bottom = (props) => {
    const {children, className, } = props;
    const css = utils.classNames("lds-genlayout-bottom", className);
    return (<div className={css}>{children}</div>);
}



GenLayout.Sidebar = (props) => {
    const {children, className, style, } = props;
    const css = utils.classNames("lds-genlayout-sidebar", className);
    return (<div className={css} style={style}>{children}</div>);
}

GenLayout.Content = (props) => {
    const {children, className, style, } = props;
    const css = utils.classNames("lds-genlayout-content", className);
    return (<div className={css} style={style}>{children}</div>);
}

GenLayout.Panel = (props) => {
    const {children, className, style, } = props;
    const css = utils.classNames("lds-genlayout-panel", className);
    return (<div className={css} style={style}>{children}</div>);
}




export default function GenLayout(props){
    let {children, className, id, onClickCTA, panel, sidebar, list, } = props;

    if(!children) children = (
        <React.Fragment>
            <GenLayout.Top>
                <TopNav />
            </GenLayout.Top>
            <GenLayout.Bottom>

                    {sidebar !== false && 
                        (
                        <GenLayout.Sidebar className="is-abs-all">
                            <Sprite.Button className="lds-genlayout-cta" onClick={onClickCTA} debug={1} style={{top: 19, left: 15, width: 240, height: 55, borderRadius: 4, }}></Sprite.Button>;
                        </GenLayout.Sidebar>
                        )
                    }


                    <GenLayout.Content style={{padding: 24, }}>
                       {list ||  <GenList />}
                    </GenLayout.Content>



                {panel && <GenLayout.Panel>{panel}</GenLayout.Panel>}
                
            </GenLayout.Bottom>
        </React.Fragment>
    );

    // render
    const css = utils.classNames("lds-genlayout", className);
    return (<div className={css} id={id}>{children}</div>);
}



