import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Menu from "../../components/lists/Menu";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import DemosButton from '../ui/DemosButton';
// import Loader from "../../components/core/Loader";
// import Layout from "../../components/containers/Layout";
// import Layout from "./Layout1";
// import PatientsPanel from "./Panel1";
// import TopNav from "../../components/nav/TopNav";
// import "../../pages/messages/Messages.scss";
import "./TopNav1.scss";



// Kemy Nissan



// MARGIN MODE TEST
const marginMode = 0; // 0 = off, 1 = current, 2 = proposed

// DEBUG
const debug = 0;


const contextItems2 = [
    {id: 1, label: "Find a location",  icon: "search", }, 
    {divider: true, },
    {id: 2, label: "Town Pharmacy HQ",  icon: "globe", isBrand: true, }, 
    {id: 3, label: "Smithville's HQ",  icon: "globe", isBrand: true, }, 
    {id: 4, label: "San Antonio Phar - 3456 South Lamar Boulevard", selected: true,  }, // San Antonio Phar - 3456 South Lamar Boulevard
    {id: 5, label: "Smithville's - South Lamar", },
    {id: 6, label: "Town Pharmacy - UT Campus",  },
    {id: 7, label: "Austin Drug",  },
    {id: 8, label: "Smithville's - North Austin",  isAltStore: true, },
];



export default function Page(){
    const {isAuth, context, contextItems} = useGlobals();
    const [option, setOption] = React.useState(0);
    const [location, setLocation] = React.useState("San Antonio Phar - 3456 South Lamar Boulevard");
    const [items, setItems] = React.useState([...contextItems2]);


    // const [tab1, setTab1] = useState(true);
    // Town Pharmacy - Congress Ave


    const onClickTab22 = (el) => {
        // alert('testing');
        // alert('tesintg 222');
        // el.classList.toggle('is-clicked');
    }

    const onClickTab = (e) => {
        e.target.classList.toggle('is-numbered');
    }

    function asdf2(){
        alert(this);
    }

    const onClickOption = (n) => {
        setOption(n);
    }

    // render

    const onClickContext = (item) => {
        if(item.id == 1) return; // skip

        // set location
        setLocation(item.label);

        // set selected
        for(var i=0; i<items.length; i++){
            items[i].selected = (item.id === items[i].id);
        }
        setItems([...items]);

        // dispatch({call: "SET_CONTEXT", item,  }); // location.pathname
        // const url = location?.pathname || "/";
        // utils.delayRedirect(dispatch, nav, url, 1000);
    }


    // options
    const options = [
        {
            tooltip: "Responsive context menu", 
            description: (
                <React.Fragment>
                    <p>Responsive context menu:</p>
                    <ol>
                        <li>Shrink the browser to 1280px and watch context menu adjust.</li>
                        <li>Click tabs to hide/show the yellow dots causing the context menu to adjust.</li>
                        <li>Select "Austin Drug" from the context menu to see a short label.</li>
                    </ol>
                </React.Fragment>
            ), 
        },
    ];
    
    // panelMode && `is-panelmode-${panelMode}`, marginMode && `is-marginmode-${marginMode}
    const css = utils.classNames("lds-topnav1-container");
    return (
        <div className={css}>

            <DemosButton options={options} selection={option} onClick={onClickOption} />

            {/* <DemosButton /> */}

            {/* <div class="lds-topnav1">
                <div class="lds-topnav1-logo"><a href="/"><i class="lds-graphic is-logo"></i></a></div>
                <a class="lds-topnav1-tab is-text-md" href="/messages">Messages</a>
                <a class="lds-topnav1-tab is-text-md" href="/schedule">Schedule<div class="lds-topnav-number">3</div></a>


                <div className="lds-topnav1-right">
                    <div to222="/logout" className="lds-spritebutton lds-spritebase is-abs" ></div>
                </div>
            </div> */}


            <div className="lds-topnav1b">
                <div className="lds-topnav1b-leftside">

                    <div className="lds-topnav-logo">
                        <a href="/"><i className="lds-graphic is-logo"></i></a>
                        
                    </div>

                    <div className="lds-topnav1b-tab is-numbered" onClick={onClickTab} >Message<div className="lds-topnav1b-number">3</div></div>
                    <div className="lds-topnav1b-tab is-numbered" onClick={onClickTab}>Tasks<div className="lds-topnav1b-number">3</div></div>
                    <div className="lds-topnav1b-tab is-numbered" onClick={onClickTab}>Schedule<div className="lds-topnav1b-number">3</div></div>
                    <div className="lds-topnav1b-tab is-numbered is-selected" onClick={onClickTab}>Refills<div className="lds-topnav1b-number">3</div></div>
                    <div className="lds-topnav1b-tab is-numbered" onClick={onClickTab}>Patients<div className="lds-topnav1b-number">3</div></div>
                    <div className="lds-topnav1b-tab is-numbered" onClick={onClickTab}>Forms<div className="lds-topnav1b-number">3</div></div>
                
                </div>
                <div className="lds-topnav1b-middle">
                    
                    {/* <div className="lds-topnav1b-middle-input">
                        <Menu items={contextItems2} onClick={onClickContext} position="top-left" width={350}>
                            <div className="lds-topnav1b-context-text">San Antonio Phar - 3456 South Lamar Boulevard</div>
                        </Menu>
                    </div> */}


                    <Menu items={items} onClick={onClickContext} position="top-left" width={350}>

                        <Sprite.Button className="lds-topnav1b-context" debug={debug}>
                            <Tooltip title="San Antonio Phar - 3456 South Lamar Boulevard" arrow>
                                <div className="lds-topnav1b-context-text">{location}</div>
                            </Tooltip>
                        </Sprite.Button>
                    </Menu>


                    {/* <Menu items={contextItems2} onClick={onClickContext} position="top-right" width={250} style={{top: 16, left: 'auto', right: 224, position: "absolute", }}>
                        <Sprite.Button className="lds-topnav1b-context" debug={debug} style={{width: 250, height: 46, maxWidth: 250, }}>
                            {context.isBrand && <Icon type="globe-white"/>}
                            <span>{context.label}</span>
                        </Sprite.Button>
                    </Menu> */}

                </div>
                <div className="lds-topnav1b-rightside"></div>
            </div>

            {/* <div class="lds-topnav1">
                <div class="lds-topnav-logo">
                    <a href="/" style="border: 0px solid red; width: 80px; height: 70px; display: block;"><i class="lds-graphic is-logo"></i></a></div><a class="lds-topnav-tab is-text-md" href="/messages">Messages</a><a class="lds-topnav-tab is-text-md" href="/tasks">Tasks</a><a class="lds-topnav-tab is-text-md" href="/schedule">Schedule<div class="lds-topnav-number">3</div></a><a class="lds-topnav-tab is-text-md is-selected" href="/refills">Refills</a><a class="lds-topnav-tab is-text-md" href="/patients">Patients</a><a class="lds-topnav-tab is-text-md" href="/forms">Forms</a><div class="lds-topnav-right"><div class="lds-menu" style="top: 16px; left: auto; right: 224px; position: absolute;"><div class="lds-spritebutton lds-spritebase lds-topnav-context" style="width: 250px; height: 46px; max-width: 250px;"><span>Town Pharmacy - Congress Ave.</span></div></div><div to222="/logout" class="lds-spritebutton lds-spritebase is-abs" style="width: 40px; height: 46px; top: 16px; left: auto; right: 150px;"></div><div to="/settings" class="lds-spritebutton lds-spritebase is-abs" style="width: 40px; height: 46px; top: 16px; left: auto; right: 106px;"></div><div class="lds-menu" style="top: 16px; left: auto; right: 64px; position: absolute;"><div class="lds-spritebutton lds-spritebase" style="width: 40px; height: 46px;"></div></div><div class="lds-menu" style="top: 16px; left: auto; right: 16px; position: absolute;"><div class="lds-spritebutton lds-spritebase" style="width: 40px; height: 46px;"></div></div></div></div> */}


            <Sprite.Split id="lds-topnav1-list">
                <Sprite.Left />
                <Sprite.Right />
            </Sprite.Split>

        </div>
    );
}