





import React from "react";
// import Icon from "./Icon";
import utils from "../../functions/utils";
// import FauxSplit from "./FauxSplit";
import './GenPhone.scss';




export default function GenPhone(props){
    const {className, id, type, name, style, children, open, openSheet, openModal, modal, sheet, onClickModalX, onClickOutside, onClickSheetX, } = props;
    // const [mode, setMode] = React.useState(2);
    // const [open2, setOpen2] = React.useState(open);

    // modal object
    const modal2 = modal || {};
    const bottomContent = modal2.bottomContent;
    let contentHeight = 712;
    let bottomHeight = modal2.bottomHeight || 0;
    if(bottomHeight) contentHeight = contentHeight - bottomHeight;

    // sheet object
    const sheet2 = sheet || {};
    const sheetContent = sheet2.content;
    let sheetHeight = sheet2.height || 100;
    sheetHeight += 50; // header height

    
    // handler
    React.useEffect(() => {
        // setTimeout(() => setPanel(true), 1000)
    }, []);

    // has bottom height?

    // const onClickOutside = () => {
    //     // alert('testing');
    //     setOpen2(false);
    // }

    const onClickInside = (e) => {
        e.stopPropagation(); // prevent trigger outside click
    }

    
    // return
    const css = utils.classNames("lds-genphone", className, type && `is-type-${type}`, name && `is-name-${name}`, open && 'is-open', openSheet && 'is-opensheet', openModal && 'is-openmodal');
    return (
        <div className={css} style={style} id={id} onClick={onClickOutside}>
            

            <div className="lds-genphone-wrapper" onClick={onClickInside}>

                <div className="lds-genphone-content">{children}</div>

                <div className="lds-genphone-modal">
                    <div className="lds-genphone-modal-title">{modal2.title}</div>
                    <div className="lds-genphone-modal-x" onClick={onClickModalX}></div>
                    <div className="lds-genphone-modal-content" style={{height: contentHeight, borderRadius: bottomHeight ? 0 : undefined, }}>{modal2.content}</div>
                    <div className="lds-genphone-modal-bottom" style={{height: bottomHeight, }}>{bottomContent}</div>
                </div>

                <div className="lds-genphone-sheet">
                    <div className="lds-genphone-sheet-inner" style={{height: sheetHeight, }}>
                        <div className="lds-genphone-sheet-title">Testing</div>
                        <div className="lds-genphone-sheet-x" onClick={onClickSheetX}></div>
                        <div className="lds-genphone-sheet-content">{sheetContent}</div>
                    </div>
                </div>


            </div>


        </div>
    );
}





// Avatar.Initails = Initails;

